class PromoBanner
{
	el: HTMLElement;
	text: HTMLElement;
	constructor(el: Element)
	{
		this.el = el as HTMLElement;
		this.text = el.querySelector(".promo-banner__content__text") as HTMLElement;

		this.GetTextLines();
		this.ScrollDown();
	}

	ScrollDown()
	{
		window.addEventListener("scroll", () =>
		{
			var lines = this.text?.querySelectorAll(".line") as NodeListOf<HTMLElement>;

			let scrollProgress = ((window.pageYOffset - this.el.offsetTop) / this.el.offsetHeight);
			if (scrollProgress < 0)
			{
				scrollProgress = 0;

				lines.forEach(line =>
				{
					line.style.backgroundPositionX = 100 + "%";
				})


			} else if (scrollProgress > 1)
			{
				scrollProgress = 1
			}
			
			var linesTheshold = 30 / lines.length;

			for (var i = 0; i < lines.length; i++)
			{
				let currentLine = lines[i] as HTMLElement;
				var currentTheshold = (i + 1) * linesTheshold;


				var upperTheshold = ((i + 1) * linesTheshold) / 100;
				var lowerTheshold = upperTheshold - (linesTheshold / 100);

				if (upperTheshold > scrollProgress && lowerTheshold < scrollProgress)
				{
					var currentWidth = ((scrollProgress * 100) / currentTheshold) * 100;

					if (currentWidth < 0)
					{
						currentWidth = 0;
					} else if (currentWidth > 100)
					{
						currentWidth = 100
					}

					currentLine.style.backgroundPositionX = (100 - currentWidth) + "%";

				} else if (upperTheshold < scrollProgress)
				{
					currentLine.style.backgroundPositionX = 0 + "%"
				}
				else if (lowerTheshold > scrollProgress)
				{
					currentLine.style.backgroundPositionX = 100 + "%"
				}
			}
		});
	}

	GetTextLines()
	{
		const text = this.text.innerText.replace(/\s+/gm, " ").trim();

		const words = text.split(" ");

		const spans = words.map((word) =>
		{
			return `<span style="display: inline-block;">${word}</span>`;
		});

		this.text.innerHTML = spans.join(" ");

		const lines = [];
		let line = [] as any;
		let previousTop = null as any;

		this.text.querySelectorAll("span").forEach((wordSpan, index) =>
		{
			const wordRect = wordSpan.getBoundingClientRect();

			if (previousTop !== wordRect.top && index > 0)
			{
				// Push the current line
				lines.push(line);
				// Start a new line
				line = [words[index]];
			} else
			{
				// Add word to the current line
				line.push(words[index]);
			}

			previousTop = wordRect.top;
		});

		// Push whatever words are left as the last line
		lines.push(line);

		let formattedLines = lines.map(line => `<p class="line">${line.join(" ")}</p>`).join("\n");

		this.text.innerHTML = formattedLines;
	}
}

document.querySelectorAll(".promo-banner").forEach(el =>
{
	new PromoBanner(el);
});