class Tab {
    tabs: NodeListOf<Element>;
    content: NodeListOf<Element>;
	constructor(el: Element) {
		this.tabs = el.querySelectorAll(".button-wrap .button");
		this.content = el.querySelectorAll(".tabs-target-wrap__item");
		this.bindEventListener();
	}

	bindEventListener() {
		this.tabs.forEach((tab, i) => {
			tab.addEventListener("click", () => {
				this.toggleTabs(i)
			})
		})
	}

	toggleTabs(target: number) {
		this.tabs.forEach((tab, i) => {
			if (i === target) {
				tab.classList.add("button--pri-black");
				tab.classList.remove("button--sec-black");
			} else {
				tab.classList.remove("button--pri-black");
				tab.classList.add("button--sec-black");
			}
		});

		this.content.forEach((item, i) => {
			if (i === target) {
				item.classList.add("tabs-target-wrap__item--open");
			} else {
				item.classList.remove("tabs-target-wrap__item--open");
			}
		});
	}
}

document.querySelectorAll(".tabs").forEach(el => {
	new Tab(el);
});