const CustomCursor = require('custom-cursor.js').default;

document.addEventListener('DOMContentLoaded', () =>
{
	var clientX = 0;
	var clientY = 0;
	var isInFocus = false;

	window.addEventListener('mouseover', (e) =>
	{
		clientX = e.x;
		clientY = e.y;
	})

	const options = {
		hideTrueCursor: false,

		focusElements: [{
			elements: '[data-mouse-takeover]',
			focusClass: 'cursor--focused',
			mouseenter: () =>
			{
				isInFocus = true;
				const elementMouseIsOver = document.elementFromPoint(clientX, clientY);

				if (elementMouseIsOver != null)
				{
					var takeoverElement = elementMouseIsOver.closest("[data-mouse-takeover]");

					var innerText = takeoverElement?.getAttribute("data-mouse-takeover");

					if (innerText != null)
					{
						var cursorText = document.querySelector(".cursor__text");

						if (cursorText != null)
						{
							cursorText.innerHTML = innerText;
						}
					}
				}

				customCursor.enable();
				customCursor.hideTrueCursor();
			},
			mouseleave: () =>
			{

				document.querySelector(".cursor__text")!.innerHTML = "";
				isInFocus = false;
				setTimeout(() =>
				{
					if (isInFocus == false)
					{
						customCursor.unhideTrueCursor();
						customCursor.disable();
					}
				}, 200);	
			}
		}]
	}

	const element = '.cursor'
	var customCursor = new CustomCursor(element, options).initialize().disable();
});