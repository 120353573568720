class GatedContent
{
	el: HTMLElement;
	cookieName?: string;
    iframe?: HTMLIFrameElement | null;
	constructor(el: HTMLElement)
	{
		this.el = el as HTMLElement;
		this.cookieName = el.dataset.cookieName;
		this.iframe = el.querySelector<HTMLIFrameElement>(".pardot-form__iframe");

		var contentSection = document.querySelector<HTMLElement>(".content-section");

		var firstImage = document.querySelector<HTMLElement>(".section:first-child .image-text-block__content__text p:first-child img");

		if (firstImage != null && contentSection != null)
		{
			var contentSectionHeight = firstImage.offsetHeight + 500 + "px";

			contentSection.style.setProperty("--gatedContentHeight", contentSectionHeight);
		}

		if (this.iframe != null)
		{
            this.iframe.addEventListener("load", () =>
            {
                var currentPage = encodeURIComponent(location.href);
                var iframeSrc = this.iframe?.src;
                var updatedSrc = iframeSrc?.replace(/(\?|&)last_url_form_completion=[^&]*/, `$1last_url_form_completion=${currentPage}`);

				if(updatedSrc != null && this.iframe != null){
					this.iframe.src = updatedSrc;
				}                
			}, {once: true});
		}
		

		// expected pardot form will send a message to the parent window at Thank You Code
		window.addEventListener('message', e => {
			if (e.data && e.data.type === "setGatedContenCookie") {
				document.cookie = this.cookieName + "=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";

				document.querySelector(".gated-content")?.remove();
				document.querySelector(".modal-backdrop")?.remove();
				var bodyElement = document.querySelector("body");
				if(bodyElement != null){
					bodyElement.classList.remove("modal-open");
					bodyElement.style.paddingRight = "";
					bodyElement.style.overflow = "";
				}
			}
		},false);
	}
}

document.querySelectorAll(".gated-content").forEach(el =>
{
	new GatedContent(el as HTMLElement);
});