class ExpandableCheckboxes
{
	nestedCheckBoxes: NodeListOf<Element>;
	constructor(el: Element)
	{
		this.nestedCheckBoxes = el.querySelectorAll(".expandable-checkboxes__nested-checkbox input");


		this.nestedCheckBoxes.forEach((checkbox: Element) =>
		{
			checkbox.addEventListener("change", () =>
			{
				let checkboxElement = checkbox as HTMLInputElement;

				let nestedList = checkbox.closest("li")?.querySelector(".expandable-checkboxes__nested-list") as HTMLElement;
				let childNodes = nestedList.childNodes;

				if (checkboxElement.checked)
				{
					var height = Array.prototype.reduce.call(childNodes, function (p, c) { return p + (c.offsetHeight || 0); }, 0) as number;

					nestedList.setAttribute("style", `height:${height + "px"};`);

					var parentNestedList = nestedList.parentElement?.closest(".expandable-checkboxes__nested-list") as HTMLElement;

					if (parentNestedList != null)
					{
						let parentHeight = parseInt(parentNestedList.style.height.replace("px", ""));

						parentNestedList.setAttribute("style", `height:${(parentHeight + height) + "px"};`);
					}					
				} else
				{
					var nestedHeight = parseInt(nestedList.style.height.replace("px", "")) as number;
					nestedList.style.height = "0px";

					var parentNestedList = nestedList.parentElement?.closest(".expandable-checkboxes__nested-list") as HTMLElement;

					if (parentNestedList != null)
					{
						var parentNestedListHeight = Array.prototype.reduce.call(parentNestedList.childNodes, function (p, c) { return p + (c.offsetHeight || 0); }, 0) as number;

						parentNestedList.setAttribute("style", `height:${(parentNestedListHeight - nestedHeight) + "px"};`);
						
					}	
				}
			});
		});
	}
}

document.querySelectorAll(".expandable-checkboxes").forEach(el =>
{
	new ExpandableCheckboxes(el);
});