class SelfSegmentation
{
	el: Element;
	overlay: Element;
    checkboxes: NodeListOf<Element>;
	constructor(el: Element)
	{
		this.el = el;
		this.overlay = el.nextElementSibling as Element;
		this.checkboxes = el.querySelectorAll("input[type=checkbox]");


		this.bindCheckbox();
		this.bindOverlay();
	}

	bindCheckbox()
	{
		this.checkboxes.forEach(checkbox => {
			checkbox.addEventListener("change", (e) =>
			{
				let eventTarget = e.currentTarget as HTMLInputElement;

				if (eventTarget.checked)
				{
					this.overlay.classList.remove("self-segmentation-component-overlay--hidden");

					this.checkboxes.forEach(closingCheckbox =>
					{
						if (closingCheckbox != checkbox)
						{
							(closingCheckbox as HTMLInputElement).checked = false;
						}
						
					});

				} else
				{
					this.overlay.classList.add("self-segmentation-component-overlay--hidden");
				}
			});
		});
	}

	bindOverlay()
	{
		this.overlay.addEventListener("click", (e) =>
		{
			this.overlay.classList.add("self-segmentation-component-overlay--hidden");

			this.checkboxes.forEach(checkbox => {
				(checkbox as HTMLInputElement).checked = false;
			});
		});
	}
}


document.querySelectorAll(".self-segmentation-component").forEach(el =>
{
	new SelfSegmentation(el);
});