import "@popperjs/core";
import "bootstrap";
import { ValidationService } from "aspnet-client-validation";
import "../styles/index.scss";

import "../scripts/components/header";
import "../scripts/components/seamless";
import "../scripts/components/select";
import "../scripts/components/tabs";
import "../scripts/components/mouseTakeover";
import "../scripts/components/splide";
import "../scripts/components/selfSegmentation";
import "../scripts/components/promoBanner";
import "../scripts/components/expandableCheckboxes";
import "../scripts/components/gatedContent";

// ASP.NET Core Client Side Validation Setup
const service = new ValidationService();
service.bootstrap();