class Header
{
	constructor(el: Element)
	{
		const chkHeaderLanguageMenuToggle = el.querySelector("#chkHeaderLanguageMenuToggle") as HTMLInputElement;

		if (chkHeaderLanguageMenuToggle)
		{
			const lblHeaderLanguageMenuToggle = el.querySelector("#lblHeaderLanguageMenuToggle") as HTMLLabelElement;
			const languageMenu = el.querySelector("#header-language-menu-items") as HTMLElement;

			document.body.addEventListener("click", event =>
			{
				const target = event.target as HTMLElement;

				if (target && target !== chkHeaderLanguageMenuToggle && target !== lblHeaderLanguageMenuToggle && target.parentElement !== lblHeaderLanguageMenuToggle)
				{
					chkHeaderLanguageMenuToggle.checked = false;
				}
				else
				{
					chkHeaderLanguageMenuToggle.checked = !chkHeaderLanguageMenuToggle.checked;
				}

				languageMenu?.setAttribute("aria-expanded", chkHeaderLanguageMenuToggle.checked.toString());
			});
		}
	}
}

document.querySelectorAll("header").forEach(el =>
{
	new Header(el);
})